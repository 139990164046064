<template>
  <main>
    <UiCheckbox v-model="datePickerRange">datePickerRange</UiCheckbox>
    <UiCheckbox v-model="datePickerConfirm">datePickerConfirm</UiCheckbox>
    <UiButton @click="showDatePicker = !showDatePicker"> date picker </UiButton>
    <UiDialog
      v-model="showDatePicker"
      position="bottom"
      close-position="left"
      close-classes="-top-1 left-2"
      class="h-[97vh] rounded-t-3xl bg-primary pt-4"
      swipe-close
    >
      <template #header>
        <div class="w-full bg-white text-center">
          <UiTitle severity="h2" class="mx-auto">Расписание</UiTitle>
        </div>
      </template>
      <UiDatePicker
        v-model="selectedDate"
        :range="datePickerRange"
        :need-confirm="datePickerConfirm"
        @close="showDatePicker = false"
      />
    </UiDialog>
    <p>selected date {{ selectedDate }}</p>

    <UiContainer class="z-20 flex flex-col" rounded="bottom">
      <p class="text-text-main">main text</p>
      <p class="text-text-primary">primary text</p>
      <p class="text-text-secondary">secondary text</p>
      <p class="text-text-tertiary">tertiary text</p>
      <p class="text-text-quaternary">quaternary text</p>
      <p class="text-text-fivefold">fivefold text</p>
      <hr />
      <div class="flex gap-5">
        <div class="size-40 shadow-primary">primary shadow</div>
        <div class="size-40 shadow-secondary">secondary shadow</div>
        <div class="size-40 shadow-tertiary">tertiary shadow</div>
        <div class="size-40 shadow-quaternary">quaternary shadow</div>
      </div>
      <div class="flex gap-5">
        <div class="size-40 border border-primary">primary border</div>
        <div class="size-40 border border-secondary">secondary border</div>
        <div class="size-40 border border-tertiary">tertiary border</div>
      </div>
      <div class="flex gap-5">
        <div class="size-40 bg-primary">primary bg</div>
        <div class="size-40 bg-secondary">secondary bg</div>
        <div class="size-40 bg-overlay">overlay bg</div>
      </div>
      <div class="flex gap-5">
        <div class="gradient-primary size-40">primary gradient</div>
        <div class="gradient-primary_solid size-40">gradient-primary_sloid</div>
        <div class="gradient-secondary size-40">--gradient-secondary</div>
        <div class="gradient-tertiary size-40">--gradient-tertiary</div>
        <div class="gradient-quaternary size-40">--gradient-quaternary</div>
        <div class="gradient-fivefold size-40">--gradient-fivefold</div>
        <div class="gradient-sixfold size-40">--gradient-sixfold</div>
      </div>
      <hr />
      <UiButton severity="primary" size="big">Primary big</UiButton>
      <UiButton severity="primary" size="small">Primary small</UiButton>
      <UiButton severity="primary" disabled>Primary disabled</UiButton>
      <UiButton severity="primary" loading>Primary loading</UiButton>
      <hr />
      <UiButton severity="secondary" size="big">secondary big</UiButton>
      <UiButton severity="secondary" size="small">secondary small</UiButton>
      <UiButton severity="secondary" disabled>secondary disabled</UiButton>
      <UiButton severity="secondary" loading>secondary loading</UiButton>
      <hr />
      <UiButton severity="tertiary" size="big">tertiary big</UiButton>
      <UiButton severity="tertiary" size="small">tertiary small</UiButton>
      <UiButton severity="tertiary" disabled>tertiary disabled</UiButton>
      <UiButton severity="tertiary" loading>tertiary loading</UiButton>
      <hr />
      <UiButton severity="quaternary" size="big">quaternary big</UiButton>
      <UiButton severity="quaternary" size="small">quaternary small</UiButton>
      <UiButton severity="quaternary" disabled>quaternary disabled</UiButton>
      <UiButton severity="quaternary" loading>quaternary loading</UiButton>
      <hr />
      <UiButton text>text button big</UiButton>
      <hr />
      <UiButtonLink severity="primary" size="big">primary link big</UiButtonLink>
      <UiButtonLink severity="primary" size="big" icon>primary link icon</UiButtonLink>
      <UiButtonLink severity="primary" size="big" disabled>primary link disabled</UiButtonLink>
      <UiButtonLink severity="primary" size="medium">primary link medium</UiButtonLink>
      <UiButtonLink severity="primary" size="small">primary link small</UiButtonLink>
      <hr />
      <UiButtonLink severity="secondary" size="big">secondary link big</UiButtonLink>
      <UiButtonLink severity="secondary" size="big" icon>secondary link icon</UiButtonLink>
      <UiButtonLink severity="secondary" size="big" disabled>secondary link disabled</UiButtonLink>
      <UiButtonLink severity="secondary" size="medium">secondary link medium</UiButtonLink>
      <UiButtonLink severity="secondary" size="small">secondary link small</UiButtonLink>
      <hr />
      <UiButton icon size="big">
        <UiIcon name="arrow-right" class="size-6" />
      </UiButton>
      <UiButton icon size="small">
        <UiIcon name="arrow-right" class="size-6" />
      </UiButton>
      <UiButton icon size="small" disabled>
        <UiIcon name="arrow-right" class="size-6" />
      </UiButton>
      <hr />
      <UiBadge>10% кешбэка</UiBadge>
    </UiContainer>

    <UiContainer class="z-20 mt-1 bg-primary pb-24">
      <UiInput v-model.trim="input" type="text" placeholder="primary">
        <template #label>label</template>
      </UiInput>

      <UiInput v-model.trim="input" size="small" type="text" placeholder="primary small">
        <template #label>label</template>
      </UiInput>

      <UiInput
        v-model.trim="input"
        type="search"
        class="pl-10"
        placeholder="primary search"
        :errors="['Ошибка']"
      >
        <template #label>label</template>
        <template #prepend>
          <ThemeIcon
            name="magnifying-glass"
            class="absolute left-5 size-4 h-full text-text-fivefold"
          />
        </template>
      </UiInput>

      <UiInput v-model.trim="input" disabled type="text" placeholder="primary disabled">
        <template #label>label</template>
      </UiInput>

      <hr />

      <UiInput v-model.trim="input" type="text" severity="secondary" placeholder="secondary">
        <template #label>label</template>
      </UiInput>

      <UiInput
        v-model.trim="input"
        size="small"
        type="text"
        severity="secondary"
        placeholder="secondary small"
      >
        <template #label>label</template>
      </UiInput>

      <UiInput
        v-model.trim="input"
        type="search"
        class="pl-10"
        severity="secondary"
        placeholder="secondary search"
        :errors="['Ошибка']"
      >
        <template #label>label</template>
        <template #prepend>
          <ThemeIcon
            name="magnifying-glass"
            class="absolute left-5 size-4 h-full text-text-fivefold"
          />
        </template>
      </UiInput>

      <UiInput
        v-model.trim="input"
        type="search"
        class="pl-10"
        severity="secondary"
        placeholder="secondary search"
        success
      >
        <template #label>label</template>
        <template #prepend>
          <ThemeIcon
            name="magnifying-glass"
            class="absolute left-5 size-4 h-full text-text-fivefold"
          />
        </template>
      </UiInput>

      <UiInput
        v-model.trim="input"
        disabled
        type="text"
        severity="secondary"
        placeholder="secondary disabled"
      >
        <template #label>label</template>
      </UiInput>

      <hr />
      <UiInput v-model.trim="input" type="text" severity="tertiary" placeholder="tertiary">
        <template #label>label</template>
      </UiInput>

      <UiInput
        v-model.trim="input"
        size="small"
        type="text"
        severity="tertiary"
        placeholder="tertiary"
      >
        <template #label>label</template>
      </UiInput>

      <UiInput
        v-model.trim="input"
        type="search"
        class="pl-10"
        severity="tertiary"
        placeholder="tertiary"
        :errors="['Ошибка']"
      >
        <template #label>label</template>
        <template #prepend>
          <ThemeIcon
            name="magnifying-glass"
            class="absolute left-5 size-4 h-full text-text-fivefold"
          />
        </template>
      </UiInput>

      <UiInput
        v-model.trim="input"
        disabled
        type="text"
        severity="tertiary"
        placeholder="tertiary disabled"
      >
        <template #label>label</template>
      </UiInput>

      <UiCheckbox v-model="checkbox" :errors="['Ошибка']"> this is checkbox </UiCheckbox>
      <UiCheckbox v-model="checkbox"> this is checkbox </UiCheckbox>
      <UiCheckbox v-model="checkbox" disabled> this is checkbox </UiCheckbox>

      <UiSwiperCards>
        <SwiperSlide
          v-for="item in 4"
          :key="item"
          :style="{ background: colors[item - 1] }"
          class="size-40"
        >
          {{ item }}
        </SwiperSlide>
      </UiSwiperCards>

      <UiSwiperPagination>
        <SwiperSlide
          v-for="item in 4"
          :key="item"
          :style="{ background: colors[item - 1] }"
          class="size-40"
        >
          {{ item }}
        </SwiperSlide>
      </UiSwiperPagination>

      {{ slider }}
      <br />
      <UiButton @click="showModalBottom = !showModalBottom"> show modal bottom </UiButton>

      <UiDialog
        v-model="showModalBottom"
        position="bottom"
        close-position="left"
        close-classes="-top-2"
        swipe-close
        class="h-[97vh] rounded-t-3xl bg-white p-4"
      >
        <template #header>
          <div class="grid w-full grid-cols-3">
            <div />
            <p class="mx-auto">this is header</p>
            <p class="ml-auto">cancel</p>
          </div>
        </template>
        <div>this is modal bottom</div>
      </UiDialog>

      <UiButton @click="showModalFade = !showModalFade"> show modal fade </UiButton>
      <UiButton @click="showPopover($event)"> Popover </UiButton>
      <br />
      <UiDialog v-model="showModalFade" position="center" class="h-screen w-screen bg-white p-4">
        <div>this is modal fade</div>
      </UiDialog>

      <UiPopover ref="popover">
        <div>this is popover this is popover</div>
      </UiPopover>

      <UiDate value="08.04.1995" />
      <div class="page-content" v-html="text" />
    </UiContainer>
    <pre>
      {{ themes.default }}
    </pre>
  </main>
</template>

<script setup lang="ts">
import { SwiperSlide } from 'swiper/vue'
import { ref } from 'vue'
import { ThemeIcon } from '@/5_entities/Configuration'
import { themes } from '@/5_entities/Configuration'
import {
  UiBadge,
  UiButton,
  UiButtonLink,
  UiCheckbox,
  UiContainer,
  UiDate,
  UiDatePicker,
  UiDialog,
  UiIcon,
  UiInput,
  UiPopover,
  UiSwiperCards,
  UiSwiperPagination,
  UiTitle
} from '@/6_shared/ui'

const input = ref('')
const checkbox = ref(false)
const slider = ref(0)
const colors = ['yellow', '#E5e', '#234', '#576']
const showModalBottom = ref(false)
const showModalFade = ref(false)
const popover = ref<typeof UiPopover | null>(null)

const showPopover = (e: MouseEvent) => {
  popover.value!.show(e)
}

const showDatePicker = ref(false)
const datePickerRange = ref(true)
const datePickerConfirm = ref(true)
const selectedDate = ref<Date | Date[] | undefined>()

const text = `
<h1>Heading 1</h1>
<h2>Heading 2</h2>
<h3>Heading 3</h3>
<h4>Heading 4</h4>
<h5>Heading 5</h5>
<h6>Heading 6</h6>
<i><h1>Italic</h1>
<h1>Heading 1</h1>
<h2>Heading 2</h2>
<h3>Heading 3</h3>
<h4>Heading 4</h4>
<h5>Heading 5</h5>
<h6>Heading 6</h6>
<p><a href="/">Сказка Льюиса Кэрролла</a> в сопровождении подробного комментария, посвященного Викторианской эпохе. Историко-бытовые заметки, рисунки и гравюры, страницы из книг и журналов того времени, интерактивные элементы создают живой образ викторианской Англии.</p>
<p>150 лет назад математик из Оксфорда написал сказку для маленькой англичанки.C тех пор придуманная им Страна чудес будоражит воображение читателей во всем мире: детей и взрослых, историков и художников, философов и поэтов. Но на страницах этой книги за веселой путаницей Страны чудес проступают очертания еще одной незнакомой нам страны — далекой и непостижимой викторианской Англии.</p>
<p>Рядом со сказкой оживает в мельчайших подробностях эпоха: можно побывать в Лондоне и в Оксфорде, оказаться в викторианском доме, рассмотреть старинные вещи, удивиться последним изобретениям, узнать, как знакомиться с королевой и почему не следует писать трехслойные письма, из чего варить фальшивый черепаховый суп, каково купаться в шерстяном жакете, где прокатиться на пенни и фартинге и когда кричать «Снип-снап-снорум!». Мало того, здесь можно не только научиться недурно крокировать и танцевать морскую кадриль, <strike>но и превратить алую розу</strike> <s>в белую, рассадить за столом</s> всех участников безумного чаепития и открыть дверцу в чудесный сад.А еще…</p>
</i><br/><p><a href="/">Сказка Льюиса Кэрролла</a> в сопровождении подробного комментария, посвященного Викторианской эпохе. Историко-бытовые заметки, рисунки и гравюры, страницы из книг и журналов того времени, интерактивные элементы создают живой образ викторианской Англии.</p>
<p>150 лет назад математик из Оксфорда написал сказку для маленькой англичанки.C тех пор придуманная им Страна чудес будоражит воображение читателей во всем мире: детей и взрослых, историков и художников, философов и поэтов. Но на страницах этой книги за веселой путаницей Страны чудес проступают очертания еще одной незнакомой нам страны — далекой и непостижимой викторианской Англии.</p>
<p>Рядом со сказкой оживает в мельчайших подробностях эпоха: можно побывать в Лондоне и в Оксфорде, оказаться в викторианском доме, рассмотреть старинные вещи, удивиться последним изобретениям, узнать, как знакомиться с королевой и почему не следует писать трехслойные письма, из чего варить фальшивый черепаховый суп, каково купаться в шерстяном жакете, где прокатиться на пенни и фартинге и когда кричать «Снип-снап-снорум!». Мало того, здесь можно не только научиться недурно крокировать и танцевать морскую кадриль, <strike>но и превратить алую розу</strike> <s>в белую, рассадить за столом</s> всех участников безумного чаепития и открыть дверцу в чудесный сад.А еще…</p>
<ul><li><b>Колода карт для игры в «Веселые семейки»</b></li><li>Тауматроп и другие оптические игрушки</li><li>Настольная игра «Война Алой и Белой розы»</li><li>Настоящий викторианский кукольный дом</li></ul>Практичная перчатка с картой Лондона<ol><li>Интерактивная картина «На железнодорожном вокзале»</li><li> Викторианские салонные игры</li><li>Улыбка без кота</li><li>Секретный шифр Льюиса Кэрролл</li><li>Голографическая картинка (лентикуляр) на обложке.</li></ol>`
</script>

<style scoped>
hr,
button {
  margin: 10px 0;
}
</style>
